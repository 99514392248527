.header {
    padding-top: 0;
    background-color: white;
}

.slider {
    padding-bottom: 100px;
}

.slider img {
    height: 700px;
    width: auto;
}

.about-div {
    width: auto;
    background-color: aliceblue;
    color: black;
    text-align: left;
}

.about {
    padding: 50px;
    /* width: 40%; */
}

.about h1 {
    font-weight: bold;
}

.about p {
    line-height: 1.8;
    color: black;
    font-weight: 400;
}

.about-img img {
    height: 507px;
    width: 630px;
}

.service {
    margin-top: 80px;
    background-color: aquamarine;
}

.service h1 {
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: bolder;
    font-size: 60px;
}

.service h4 {
    padding: 30px;
}

.service h5 {
    padding-bottom: 30px;
}

.contact {
    padding-top: 50px;
    
}

.row {
    padding-top: 50px;
}

.details{
    padding-left: 100px;
}
.contact h1 {
    font-size: 60px;
    font-weight: bolder;
}

.contact h6 {
    text-align: left;
    line-height: 2;
}

.contact h2 {
    padding-bottom: 30px;
    text-align: left;
}

.mapFrame{
    height: 500px;
    padding-bottom: 20px;
}